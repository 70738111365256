import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"

import Section from "../components/section"
import TwmButton from "../components/twm-button"
import Part from "../components/part"

const OurServicesPage = props => (
  <Layout>
    <SEO title="True Wealth Planning Services" />

    <BackgroundImage
      fluid={props.data.ourServicesImage.childImageSharp.fluid}
      alt="business paperwork"
    >
      <Header headerText="True Wealth Planning Services"></Header>
    </BackgroundImage>
    <Section>
      <p>
        The best way to achieve 'true wealth' will be different for each client.
        At True Wealth Management, we have developed a number of bespoke
        propositions for our clients. They all bear the same quality hallmarks
        and characteristics i.e. delivering a value for money proposition
        tailored to your needs underpinned by institutional quality solutions
        and impartial, expert advice. At a high level we refer to these as our
        Private Client Proposition, our Adviser Assist Programme and our Self
        Serve Programme.
      </p>
      <Part>
        <p>
          <strong>Private Client Proposition</strong> – Our flagship proposition
          offers a highly bespoke financial planning and wealth management
          service to help ensure you achieve your ‘true wealth’.
        </p>
        <TwmButton
          toRef="/true-wealth-planning-services/#private-client-proposition"
          style={{ marginBottom: "8vh" }}
        >
          {" "}
          Find out more...
        </TwmButton>
      </Part>

      <Part>
        <p>
          <strong>Adviser Assist Programme</strong> – Helps meet the more
          ‘ad-hoc’ needs of our clients with expert advice and best of breed
          solutions.
        </p>

        <TwmButton
          toRef="/true-wealth-planning-services/#adviser-assist-programme"
          style={{ marginBottom: "8vh" }}
        >
          {" "}
          Find out more...
        </TwmButton>
      </Part>
      <Part>
        <p>
          <strong>Self Service Programme</strong> – Provides you with the tools
          to help control and manage your own financial affairs.
        </p>
        <TwmButton
          toRef="/true-wealth-planning-services/#self-serve-programme"
          style={{ marginBottom: "8vh" }}
        >
          {" "}
          Find out more...
        </TwmButton>
      </Part>
      <h1 id="private-client-proposition" style={{ paddingTop: "8vh" }}>
        Private Client Proposition
      </h1>

      <p>
        This is our flagship proposition. We have invested in our business to be
        able to provide our clients with the quality of financial planning and
        wealth management proposition to rival that offered by any leading
        Independent Financial Advisers anywhere in the UK.
      </p>

      <p>It encompasses:</p>

      <ul>
        <li>
          Construction of a bespoke financial plan to give you clarity over
          what's needed to achieve your financial objectives.
        </li>
        <li>
          We will invest time understanding your objectives &amp; priorities,
          use leading industry tools to assess tolerance &amp; capacity for
          investment risk and examine income &amp; expenditure requirements.
        </li>
        <li>
          The advice will encompass key areas of your financial planning and
          wealth management requirements including advice on investment
          management, estate planning*, retirement advice, protection issues and
          mortgages.
        </li>
        <li>
          Once your plan is established we will proactively review it with you
          each year to keep it on track and keep abreast of any challenges or
          opportunities that present themselves.
        </li>
      </ul>
      <p>
        *Estate planning is not regulated by the Financial Conduct Authority.
      </p>
      <TwmButton toRef="/contact-us">Contact Us</TwmButton>

      <h1 id="adviser-assist-programme" style={{ paddingTop: "8vh" }}>
        Adviser Assist Programme
      </h1>
      <p>
        Many of our clients have simpler or more ad-hoc needs and so we have
        developed our Adviser Assist programme to make sure we can cater for
        their needs in a more reactive fashion.
      </p>

      <p>
        This in no way means the advice or the solutions provided are of
        inferior quality, it simply reflects our desire to deliver a service
        that will be more affordable to clients with simpler needs.
      </p>

      <p>
        All Adviser Assist clients will benefit from our expert advice and best
        of breed solutions. Wherever we receive ongoing adviser charging fees we
        will also provide a proactive review service.
      </p>
      <TwmButton toRef="/contact-us">Contact Us</TwmButton>

      <h1 id="self-serve-programme" style={{ paddingTop: "8vh" }}>
        Self Serve Programme
      </h1>
      <p>
        Many of our clients have very straightforward requirements or wish to
        take more responsibility for arranging and managing their own affairs.
      </p>

      <p>
        This service is provided via{" "}
        <a href="https://destinationfp.com">Destination Financial Planning</a>,
        part of the <Link to="/true-wealth-group">True Wealth Group</Link> and
        is mainly delivered by phone and post and/or through the website.
      </p>
      <TwmButton toRef="/contact-us">Contact Us</TwmButton>
    </Section>
  </Layout>
)

export default OurServicesPage

export const pageQuery = graphql`
  query {
    ourServicesImage: file(relativePath: { eq: "our-services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
